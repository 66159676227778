import { useMatch } from '@reach/router';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import { useInsightsThoughtspotEnabled } from '../utils/useInsightsThoughtspotEnabled';

export type InsightsView = 'legacy' | 'thoughtspot';

export interface InsightsViewContextValue {
  insightsView: InsightsView;
  setInsightsView: React.Dispatch<React.SetStateAction<InsightsView>>;
  isThoughtSpotEnabled: boolean;
  isThoughtSpotView: boolean;
}

const InsightsViewContext = createContext<InsightsViewContextValue>({
  insightsView: 'legacy',
  setInsightsView: () => {},
  isThoughtSpotEnabled: false,
  isThoughtSpotView: false,
});

export const useInsightsViewContext = () => useContext(InsightsViewContext);

export const InsightsViewContextProvider: FC = ({ children }) => {
  const isThoughtSpotEnabled = useInsightsThoughtspotEnabled();
  const [insightsView, setInsightsView] = useState<InsightsView>('legacy');
  const tsMatch = useMatch('/insights-studio/ts/*');
  const legacyDashboardMatch = useMatch('/insights-studio/:dashboardId/*');
  useEffect(() => {
    if ((!isThoughtSpotEnabled || legacyDashboardMatch) && !tsMatch) {
      setInsightsView('legacy');
    } else {
      setInsightsView('thoughtspot');
    }
  }, [isThoughtSpotEnabled, legacyDashboardMatch, tsMatch]);

  const value = useMemo(() => {
    return {
      insightsView,
      isThoughtSpotView: insightsView === 'thoughtspot',
      setInsightsView,
      isThoughtSpotEnabled,
    };
  }, [insightsView, setInsightsView, isThoughtSpotEnabled]);

  return <InsightsViewContext.Provider value={value}>{children}</InsightsViewContext.Provider>;
};
